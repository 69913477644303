@import "./node_modules/antd/dist/antd.less"; // 引入官方提供的 less 样式入口文件
// @primary-color: black;

body {
  background-color: #f0f2f5;
}

// #region btcpay form
.btcpay-form {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btcpay-form--inline {
  flex-direction: row;
}
.btcpay-form--block {
  flex-direction: column;
}
.btcpay-form--inline .submit {
  margin-left: 15px;
}
.btcpay-form--block select {
  margin-bottom: 10px;
}
.btcpay-form .btcpay-custom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btcpay-form .plus-minus {
  cursor: pointer;
  font-size: 25px;
  line-height: 25px;
  background: rgba(0, 0, 0, 0.1);
  height: 30px;
  width: 45px;
  border: none;
  border-radius: 60px;
  margin: auto;
}
.btcpay-form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 1px solid transparent;
  display: block;
  padding: 1px;
  margin-left: auto;
  margin-right: auto;
  font-size: 11px;
  cursor: pointer;
}
.btcpay-form select:hover {
  border-color: #ccc;
}
.btcpay-form .submit {
  width: 150px;
}
#btcpay-input-price {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  box-shadow: none;
  text-align: center;
  font-size: 25px;
  margin: auto;
  border-radius: 5px;
  line-height: 35px;
  background: #fff;
}
// #endregion
